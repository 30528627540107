import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import { enqueueSnackbar } from "notistack";

import { format as DateFormat } from "date-fns";
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  IconButton,
  Input,
  Paper,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import LineStyleOutlinedIcon from "@mui/icons-material/LineStyleOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import AddCardIcon from "@mui/icons-material/AddCard";
import DeleteIcon from "@mui/icons-material/Delete";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import EditIcon from "@mui/icons-material/Edit";
import ListIcon from "@mui/icons-material/List";
import RateReviewIcon from "@mui/icons-material/RateReview";

import { useTheme } from "@mui/material";
import { getIsPaid } from "../_services/user.service";
import { DialogConfirm } from "../_examples/DialogConfirm";
import { SequenceDisplay } from "../sequences";

export { LessonMain };

function LessonMain() {
  const theme = useTheme();
  const [isPaid, setIsPaid] = useState(false);
  const navigate = useNavigate();
  const cognitoUser = Auth.user.username;
  const [lessons, setLessons] = useState([]);
  const [sortBy, setSortBy] = useState("date");
  const [searchInput, setSearchInput] = useState("");
  const [apiData, setApiData] = useState("");
  const [user, setUser] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const dialogInitialState = {
    open: false,
    title: "",
    text: "",
    targetId: null,
    buttons: "",
  };
  const [dialog, setDialog] = useState(dialogInitialState);

  const handleDelete = (row) => {
    const lesson = lessons[row];
    const sCount = lesson.sentenceCount ? lesson.sentenceCount : 0;
    setDialog({
      open: true,
      title: lesson.name,
      text: `This lesson has ${sCount} sentences - are you sure you want to delete it?`,
      targetId: lesson._id,
      buttons: "OK,Cancel",
    });
  };

  // handler for Dialog close - api or other action
  const handleDeleteCofirm = async (value) => {
    if (value === "OK") {
      if (dialog.targetId) {
        await API.del(
          "lessonsApi",
          `/lessons/one-lesson?id=${dialog.targetId}`
        );
      }
      const items = lessons.filter((s) => {
        return s._id !== dialog.targetId;
      });
      setLessons(items);
      const apiItems = apiData.filter((s) => {
        return s._id !== dialog.targetId;
      });
      setApiData(apiItems);
    }
    setDialog(dialogInitialState);
  };

  // api is api.get(`lesson/owner/${ownerId}`)
  useEffect(() => {
    const getLessons = async () => {
      setIsLoading(true);
      try {
        const response = await API.get(
          "lessonsApi",
          `/lessons/get-lessons?ownerid=${cognitoUser}`
        );
        if (response.error) {
          enqueueSnackbar(response.appData.message, { variant: "error" });
          setIsLoading(false);
        } else {
          setApiData(response.appData.lessons);
          const arr = response.appData.lessons.sort(function compare(a, b) {
            var dateA = new Date(a.lastUsed);
            var dateB = new Date(b.lastUsed);
            return dateB - dateA;
          });
          setLessons(arr);
          setUser(response.appData.user);
          setIsPaid(() => getIsPaid(response.appData.user));
          localStorage.setItem(
            "gvLangUser",
            JSON.stringify(response.appData.user)
          );
          setIsLoading(false);
        }
      } catch (err) {
        console.error(err);
      }
    };
    getLessons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const controlProps = (item) => ({
    checked: sortBy === item,
    onChange: handleSortByChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const searchItems = (searchValue) => {
    if (searchValue !== "") {
      const filteredData = apiData.filter((item) => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase());
      });
      setLessons(filteredData);
    } else {
      setLessons(apiData);
    }
    setSearchInput(searchValue);
  };

  const handleSortByChange = (event) => {
    const val = event.target.value;
    if (val === "date") {
      setLessons(
        lessons.sort(function compare(a, b) {
          var dateA = new Date(a.lastUsed);
          var dateB = new Date(b.lastUsed);
          return dateB - dateA;
        })
      );
    } else {
      setLessons(
        lessons.sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        })
      );
    }
    setSortBy(val);
  };

  return isLoading ? (
    <Box>Loading lessons ...</Box>
  ) : (
    <Paper
      sx={{
        width: "100%",
        margin: "1px",
        height: "90vh",
        backgroundColor: theme.palette.neutral.main,
      }}
    >
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        padding="10px"
        backgroundColor="white"
      >
        <Typography variant="body1" sx={{ marginRight: "50px" }}>
          Lesson List
        </Typography>
        <Typography variant="caption" sx={{ marginRight: "10px" }}>
          Sort by:
        </Typography>
        <Radio {...controlProps("date")} size="small" />
        <Typography variant="caption" sx={{ marginRight: "10px" }}>
          Last used
        </Typography>
        <Radio {...controlProps("name")} size="small" />
        <Typography variant="caption">Name</Typography>
        <Input
          sx={{ marginLeft: "25px", fontSize: "0.8rem" }}
          placeholder="search"
          name="searchInput"
          type="text"
          value={searchInput}
          onChange={(e) => searchItems(e.target.value)}
        />
        <Box sx={{ marginLeft: "30px" }}>
          <Button
            onClick={() => navigate("add")}
            variant="text"
            size="small"
            startIcon={<AddCardIcon />}
          >
            Create Lesson
          </Button>
          <Button
            onClick={() => navigate(`import`)}
            disabled={!isPaid}
            variant="text"
            size="small"
            startIcon={<LibraryBooksOutlinedIcon />}
            sx={{ marginLeft: "30px" }}
          >
            Import Lesson
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          margin: "10px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          height: "90%",
          overflowY: "scroll",
        }}
      >
        {lessons === "" && <Box>Retrieving lessons ...</Box>}
        {lessons.map((lesson, ix) => (
          <Card
            key={lesson._id}
            sx={{
              width: "45%",
              maxWidth: "500px",
              maxHeight: "400px",
              margin: "10px",
              backgroundColor: theme.palette.grey[200],
            }}
          >
            <CardHeader title={lesson.name} sx={{ fontSize: "0.8rem" }} />
            <CardContent
              sx={{
                borderRadius: "8px",
                marginLeft: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px",
                  fontWeight: 700,
                }}
              >
                <Typography
                  variant="caption"
                  color="text.primary"
                  display="block"
                >
                  {`Last used: `}
                  {DateFormat(new Date(lesson.lastUsed), "dd-MMM-yy hh:mm a")}
                </Typography>
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  disabled={!isPaid}
                  onClick={() => navigate(`sentences/${lesson._id}`)}
                  startIcon={<ListIcon />}
                >
                  <Typography variant="caption">
                    {lesson.sentenceCount} sentences
                  </Typography>
                </Button>
              </Box>
              <Stack direction="row" spacing={10} mt={1} mb={2}>
                <Stack>
                  <Stack direction="row" spacing={1}>
                    <Box
                      sx={{
                        width: 15,
                        height: 15,
                        backgroundColor: user.preferences.origColor,
                        marginLeft: "3px",
                      }}
                    />

                    <Typography variant="caption" color="text.primary">
                      {lesson.origVoice}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Box
                      sx={{
                        width: 15,
                        height: 15,
                        backgroundColor: user.preferences.transColor,
                        marginLeft: "3px",
                      }}
                    />

                    <Typography variant="caption" color="text.primary">
                      {lesson.transVoice}
                    </Typography>
                  </Stack>
                  <Typography variant="caption" display="block">
                    Course: {lesson.course}
                  </Typography>
                </Stack>
                <SequenceDisplay
                  seqName={lesson.sequenceDetails.name}
                  seqText={lesson.sequenceDetails.seqText}
                />
              </Stack>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  padding: "5px",
                  fontWeight: 700,
                }}
              >
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  disabled={!isPaid}
                  onClick={() => navigate(`edit/${lesson._id}`)}
                  startIcon={<EditIcon />}
                >
                  Lesson
                </Button>
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  disabled={!isPaid}
                  onClick={() => navigate(`review/${lesson._id}`)}
                  startIcon={<RateReviewIcon />}
                >
                  Review
                </Button>
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  disabled={!isPaid}
                  onClick={() => navigate(`free/${lesson._id}`)}
                  startIcon={<VolumeUpIcon />}
                >
                  Free Play
                </Button>
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  disabled={!isPaid}
                  onClick={() => navigate(`seq/${lesson._id}`)}
                  startIcon={<LineStyleOutlinedIcon />}
                >
                  Sequence Play
                </Button>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => handleDelete(ix)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
      <DialogConfirm
        onClose={handleDeleteCofirm}
        open={dialog.open}
        dialogTitle={dialog.title}
        dialogText={dialog.text}
        dialogButtons={dialog.buttons}
      />
    </Paper>
  );
}
