import React, { useState, useEffect } from "react";
import { Auth, API } from "aws-amplify";
import { enqueueSnackbar } from "notistack";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Input,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { clearGvsDb, getRecordCount } from "../_helpers/idb";
import { getIsPaid } from "../_services/user.service";
import { WindowTitle } from "../_helpers/gvlang-components";
import { useNavigate } from "react-router-dom";

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        padding: "10px",
        color: (theme) =>
          theme.palette.mode === "dark" ? "grey.300" : "grey.800",
        fontSize: (theme) => theme.typography.fontSize,
        fontFamily: (theme) => theme.typography.fontFamily,
        ...sx,
      }}
      {...other}
    />
  );
}

export { Account };

function Account() {
  const navigate = useNavigate();
  const maxTTS = 200000;
  const cognitoUser = Auth.user.username;
  const [user, setUser] = useState(null);
  const [isPaid, setIsPaid] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [downloadChecked, setDownloadChecked] = useState(false);
  const [message, setMessage] = useState("");
  const headers = `origText\ttransText\tlesson\tTLVoice\tNLVoice`;
  const isWindows = navigator.userAgent.indexOf("Windows") !== -1;
  const CRLF = isWindows ? "\r\n" : "\n";
  const [tabValue, setTabValue] = useState("one");
  const [idbCount, setIdbCount] = useState(0);

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const isRenewWindowOpen = () => {
    let rtn = false;
    if (user) {
      const today = new Date();
      if (user?.account.dateRenewedThru) {
        const renewDate = new Date(user.account.dateRenewedThru);
        const diff = renewDate.getTime() - today.getTime();
        const div = 1000 * 60 * 60 * 24;
        const dayDiff = Math.round(diff / div);
        if (dayDiff < 10) {
          rtn = true;
          console.log("opening");
        }
      } else {
        console.log("no date renewed through");
        rtn = true;
      }
    }
    return rtn;
  };

  useEffect(() => {
    const getUser = async () => {
      const cognitoUser = Auth.user.username;
      const response = await API.get(
        "usersApi",
        `/users/one-user?id=${cognitoUser}`
      );
      if (response.error) {
        enqueueSnackbar(response.appData.message, { variant: "error" });
      } else {
        setUser(response.appData);
        setIsPaid(() => getIsPaid(response.appData));
        const count = await getRecordCount();
        setIdbCount(count);
      }
    };
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

  const handleDownload = async () => {
    let content = headers + CRLF;
    const response = await API.get(
      "lessonsApi",
      `/lessons/get-lessons?ownerid=${cognitoUser}`
    );
    let lessonList = response.appData.lessons;
    for (let index = 0; index < lessonList.length; index++) {
      const lessonId = lessonList[index]._id;
      const response2 = await API.get(
        "lessonsApi",
        `/lessons/one-lesson?id=${lessonId}`
      );
      let lesson = response2.appData.lesson;
      if (lesson.sentences.length) {
        const lessonStr = `${lesson.name}\t${lesson.origVoice}\t${lesson.transVoice}`;
        for (let ix = 0; ix < lesson.sentences.length; ix++) {
          let sentence = lesson.sentences[ix];
          content += `${sentence.origText}\t${sentence.transText}\t${lessonStr}${CRLF}`;
        }
      }
    }
    const filename = `AllSentences ${new Date().toISOString()}.txt`;
    const link = document.createElement("a");
    const file = new Blob([content], { type: "text/plain" });
    link.href = URL.createObjectURL(file);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);
    enqueueSnackbar("All sentences downloaded to " + filename, {
      variant: "success",
    });
    setDownloadChecked(false);
  };

  function handleGvsClear() {
    clearGvsDb();
    setIdbCount(0);
  }

  const handleCheckout = async () => {
    const result = await API.post(
      "stripeApi",
      `/stripe/create-checkout-session`,
      {
        body: {
          cognitoUser: cognitoUser,
        },
      }
    );
    if (!result.error) {
      clearGvsDb();
      window.location.href = result.appData.url;
    }
    console.log(result);
  };

  const handleRenew = async (couponCode) => {
    try {
      console.log(user);
      //
      const result = await API.put(
        "usersApi",
        `/users/renew-sub?id=${cognitoUser}`,
        {
          body: {
            couponCode: couponCode,
          },
        }
      );
      if (!result.error) {
        setUser((prev) => {
          return {
            ...prev,
            account: {
              ...prev.account,
              dateRenewedThru: result.appData.renewedThru,
              anchorDay: result.appData.anchorDay,
              ttsCharUsed: result.appData.ttsCharUsed,
            },
          };
        });
        setIsPaid(true);
        localStorage.setItem("gvLangUser", JSON.stringify(user));
        setCouponCode("");
        enqueueSnackbar("Subscription renewed", { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
      clearGvsDb();
    } catch (err) {
      enqueueSnackbar(err, { variant: "error" });
    }
  };

  return (
    user && (
      <Paper sx={{ paddingBottom: "20px" }}>
        <Stack direction="row" sx={{ margin: "15px" }} spacing={3}>
          <WindowTitle>Account</WindowTitle>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Box sx={{ width: "30%", marginLeft: "10px" }}>
            {user && (
              <Grid container>
                <Grid item xs={4}>
                  <Item>Email</Item>
                </Grid>
                <Grid item xs={8}>
                  <Item sx={{ fontWeight: "700" }}>{user.email}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Item>Date joined:</Item>
                </Grid>
                <Grid item xs={8}>
                  <Item sx={{ fontWeight: "700" }}>
                    {user.account.dateJoined.split("T")[0]}
                  </Item>
                </Grid>
                <Grid item xs={4}>
                  <Item>Renewed thru:</Item>
                </Grid>
                <Grid item xs={8}>
                  <Item sx={{ fontWeight: "700" }}>
                    {user.account.dateRenewedThru?.split("T")[0]}
                  </Item>
                </Grid>
                <Grid item xs={12}>
                  <Item>
                    {!isPaid && (
                      <Typography
                        color="red"
                        variant="body2"
                        sx={{ fontWeight: 600 }}
                      >
                        You need to renew!
                      </Typography>
                    )}
                    {!user.account?.dateRenewedThru && (
                      <Typography variant="caption" color="blue">
                        Import/Export enabled after 1st renewal
                      </Typography>
                    )}
                  </Item>
                </Grid>
                <Grid item xs={4}>
                  <Item>Coupon code</Item>
                </Grid>
                <Grid item xs={8}>
                  <Item sx={{ fontWeight: "700" }}>
                    {user.account.couponCode}
                  </Item>
                </Grid>
                <Grid item xs={4}>
                  <Item>TTS char used</Item>
                </Grid>
                <Grid item xs={8}>
                  <Item sx={{ fontWeight: "700" }}>
                    {user.account.ttsCharUsed}
                  </Item>
                </Grid>
                <Grid item xs={12}>
                  {user.account.ttsCharUsed > maxTTS && (
                    <Typography
                      color="red"
                      variant="body2"
                      sx={{ fontWeight: 600 }}
                    >
                      Renewal needed after {maxTTS} characters
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {user?.isAdmin && (
                    <Button onClick={() => navigate("/app/users")}>
                      User List
                    </Button>
                  )}
                </Grid>
              </Grid>
            )}
          </Box>
          <Box sx={{ width: "65%" }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="wrapped label tabs example"
            >
              <Tab value="one" label="Renew Subscription" wrapped />
              <Tab value="two" label="Download" wrapped />
              <Tab value="three" label="Browser storage" wrapped />
            </Tabs>
            {tabValue === "one" && (
              <Stack spacing={4} mt={3}>
                {!isRenewWindowOpen() && (
                  <Typography
                    variant="body2"
                    color="indigo"
                    sx={{ fontWeight: 700 }}
                  >
                    Renewal is allowed only within 10 days of the current
                    renewed through date
                  </Typography>
                )}
                <Stack>
                  <Typography variant="body2">
                    To renew using a coupon, enter your coupon code and click
                    RENEW
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <Input
                      placeholder="coupon code"
                      name="couponCode"
                      required
                      size="small"
                      type="text"
                      value={couponCode || ""}
                      onChange={(e) => setCouponCode(e.target.value)}
                    />
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={!couponCode || !isRenewWindowOpen()}
                      size="small"
                      onClick={() => handleRenew(couponCode)}
                    >
                      Renew
                    </Button>
                  </Stack>
                </Stack>
                <Stack sx={{ width: "400px" }}>
                  <Typography variant="body2">
                    To renew with a credit card payment, click the PAY button
                    and enter your payment info
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={couponCode !== "" || !isRenewWindowOpen()}
                    size="small"
                    onClick={() => handleCheckout()}
                    sx={{ width: "200px" }}
                  >
                    Pay $5.00
                  </Button>
                  <Box typography="caption" color="blue">
                    {message}
                  </Box>
                </Stack>
              </Stack>
            )}
            {tabValue === "two" && (
              <Stack
                spacing={1}
                sx={{
                  padding: "15px",
                  width: "400px",
                }}
              >
                <Box typography="body2">
                  You can download all of your sentences, from all lessons, at
                  any time.
                </Box>
                <FormControlLabel
                  control={<Checkbox />}
                  name="download"
                  label={
                    <Typography variant="body2">
                      I want to download my sentences
                    </Typography>
                  }
                  onChange={() => setDownloadChecked(!downloadChecked)}
                  checked={downloadChecked}
                  size="sm"
                />{" "}
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  disabled={!downloadChecked}
                  onClick={() => handleDownload()}
                  sx={{ width: "150px" }}
                >
                  Download
                </Button>
              </Stack>
            )}
            {tabValue === "three" && (
              <Stack mt={2} spacing={3} sx={{ width: "400px" }}>
                <Box typography="body2">
                  PhraseKeeperTTS uses browser storage to cache audio files and
                  limit your use of TTS against the monthly limit. You can clear
                  the cache at any time.
                </Box>
                <Box typography="body2" sx={{ fontWeight: 600 }}>
                  Currently you have {idbCount} PhraseKeeperTTS audio records in
                  browser storage.
                </Box>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => handleGvsClear()}
                  sx={{ width: "150px" }}
                >
                  Clear records
                </Button>
              </Stack>
            )}
          </Box>
        </Stack>
      </Paper>
    )
  );
}
